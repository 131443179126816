
export default {
  props: {
    searchLoader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    checkoutLoader: false,
  }),

  methods: {
    start(flag) {
      document.body.style.overflow = 'hidden'
      switch (flag) {
        case 'checkout':
          this.checkoutLoader = true
          return
        default:
          this.loading = true
      }
    },
    finish() {
      document.body.style.overflow = ''
      this.loading = false
      this.checkoutLoader = false
    },
  },
}
